<!--
 * @Description: 项目中心 > 项目日常管理 > 巡更管理 > 巡更通用内容
 * @Author: XFJ
 * @Date: 2019-08-20 19:19:37
 * @LastEditors: 小广
 * @LastEditTime: 2019-08-21 10:20:58
 -->
<template>
  <div class="comContent-wrapper">
    <list
      ref="list"
      exportPermission="export"
      exportMethod="delay"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #headerSlot>
        <v-button text="新增" permission="add" @click="createContent"></v-button>
      </template>
      <template #searchSlot>
        <v-select2 label="所属公司" v-model="searchParams.regionId" v-bind="regionSel2Params" @onChange="onRegionIdChange" ></v-select2>
        <v-select label="一级分类" v-model="searchParams.firstLevel" :options="firstOps" @change='getSecondOpsFun'></v-select>
        <v-select label="二级分类" v-model="searchParams.secondLevel" :options="secondOps"></v-select>
        <v-datepicker label="录入时间段" :startTime.sync="searchParams.startTime" :endTime.sync="searchParams.endTime"></v-datepicker>
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" permission="update" @click="editContent(scope.row)"></v-button>
        <v-button text="删除" type="text" permission="delete" @click="delContent(scope.row)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { getListURL, exportListURL, delContentUrl } from './api'
import { regionParams } from 'common/select2Params'
import { getFirstOpts, getSecondOps } from './../map'

export default {
  name: 'comContent',
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      searchParams: {
        firstLevel: undefined,
        secondLevel: undefined,
        startTime: '',
        endTime: ''
      },
      regionSel2Params: regionParams,
      levelOps: [{
        text: '全部',
        value: undefined
      }],
      firstOps: [],
      secondOps: [{
        text: '全部',
        value: undefined
      }],
      headers: [
        {
          prop: 'parentName',
          label: '一级分类'
        },
        {
          prop: 'name',
          label: '二级分类'
        },
        {
          prop: 'inUserName',
          label: '创建人'
        },
        {
          prop: 'regionName',
          label: '所属公司'
        },
        {
          prop: 'content',
          label: '巡更内容'
        },
        {
          prop: 'intime',
          label: '创建时间'
        }
      ]
    }
  },
  async created () {
    let options = await getFirstOpts()
    this.firstOps = this.levelOps.concat(options)
  },
  mounted () {
    // 检索条件回显联动
    if (this.searchParams.firstLevel) {
      getSecondOps(this.searchParams.firstLevel, this.secondOps)
    }
  },
  methods: {
    getSecondOpsFun (value) {
      this.searchParams.secondLevel = undefined
      this.secondOps = [{
        text: '全部',
        value: undefined
      }]
      getSecondOps(value, this.secondOps)
    },
    createContent () {
      this.$router.push({
        name: 'comContentForm'
      })
    },
    editContent (row) {
      this.$router.push({
        name: 'comContentForm',
        query: {
          id: row.id
        }
      })
    },
    delContent (row) {
      let _this_ = this
      this.$confirm('确定删除该巡更内容？', {
        title: '提示'
      }).then(confirm => {
        if (confirm) {
          _this_.$axios.delete(delContentUrl + row.id).then(res => {
            if (res.status === 100) {
              _this_.$refs.list.searchData()
            }
          })
        }
      })
    },
    async onRegionIdChange () {
      let options = await getFirstOpts({ regionId: this.searchParams.regionId })
      this.firstOps = [...this.levelOps, ...options]
    }
  }
}
</script>
